import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Nav from "./nav"
import "./css/layout.styl"
import Landscape from "./landscape"
import Footer from "./footer"
import Information from "./info-top-comp"
// import LogRocket from 'logrocket';
// LogRocket.init('gu3slm/autoradiograph');
// LogRocket.identify('auth0|5ee98ed8e1832700192d3e9d', {
//   name: 'Amandine Davre',
//   email: 'amandine.davre@gmail.com',
// });

const Layout = ({ children, location, noMargin, top, isLogin, setIsAskingDefaultLang }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="App">
      <Nav location={location} siteTitle={data.site.siteMetadata.title} setIsAskingDefaultLang={setIsAskingDefaultLang} />
      <main className={noMargin && "noMargin"}>{children}</main>
      {/* <Landscape /> */}
      {location && !location.pathname.indexOf('archive') >= 0 && !isLogin && <Landscape />}
      <Footer location={location} />
      {top && <Information />}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
