import React, { useState } from "react"
import { Link, useIntl } from "gatsby-plugin-intl"

import useNavTexts from "../hooks/use-nav-text"
import logoJpAutoradiograph from "../images/svg/title-jp.svg"
import Language from "./language"

// Auth0
import { login, signup, logout, isAuthenticated, getProfile } from "../utils/auth"

import "./css/nav.styl"

const Nav = ({ location, setIsAskingDefaultLang }) => {
  const {
    top,
    info,
    archive,
    history,
    shortmovie,
    print,
    contact,
    patreon,
    loginText,
    logoutText,
  } = useNavTexts()[0]

  const intl = useIntl()
  const language = intl.locale

  const [spMenuActive, setSpMenuActive] = useState(false)

  const onSpMenuHandle = off => {
    if (off) {
      return setSpMenuActive(false)
    }
    let currentSpMenuActive = spMenuActive
    currentSpMenuActive = !currentSpMenuActive
    setSpMenuActive(currentSpMenuActive)
  }

  const getSpMenuClasses = () => {
    let activeCheck = spMenuActive ? "active" : ""
    return `menu-trigger ${activeCheck}`
  }

  const checkArchivePage = () => {
    return location.pathname.indexOf('archive') >= 0
  }

  return (
    <React.Fragment>
      <nav className="gNav">
        {/* Logo */}
        <div className="logo" onClick={() => onSpMenuHandle("off")}>
          <Link to="/">
            {language === "ja" ? (
              <img src={logoJpAutoradiograph} alt="放射線像" />
            ) : (
                "Autoradiograph"
              )}
          </Link>
        </div>

        {/* Links */}
        <ul className={"navLink " + language + (spMenuActive ? " active" : "")} itemType="https://schema.org/SiteNavigationElement">
          <li itemProp="name" onClick={() => onSpMenuHandle("off")}>
            <Link itemProp="url" to="/" activeClassName="active">
              {top[language]}
            </Link>
          </li>
          <li itemProp="name" onClick={() => onSpMenuHandle("off")}>
            <Link itemProp="url"
              to="/info"
              className={
                location && location.pathname.indexOf("info") >= 0
                  ? "active"
                  : ""
              }
            >
              {info[language]}
            </Link>
          </li>
          <li itemProp="name" onClick={() => onSpMenuHandle("off")}>
            <Link itemProp="url"
              to="/archive"
              className={
                location && location.pathname.indexOf("archive") >= 0
                  ? "active"
                  : ""
              }
            >
              {archive[language]}
            </Link>
          </li>
          <li itemProp="name" onClick={() => onSpMenuHandle("off")}>
            <Link itemProp="url"
              to="/history/press"
              className={
                location && location.pathname.indexOf("history") >= 0
                  ? "active"
                  : ""
              }
            >
              {history[language]}
            </Link>
          </li>
          <li itemProp="name" onClick={() => onSpMenuHandle("off")}>
            <Link itemProp="url" to="/shortmovie" activeClassName="active">
              {shortmovie[language]}
            </Link>
          </li>
          {/* <li  itemProp="name" onClick={() => onSpMenuHandle("off")}>
              <Link  itemProp="url" to="/print">{print}</Link>
            </li> */}
          <li itemProp="name" onClick={() => onSpMenuHandle("off")}>
            <Link itemProp="url" to="/contact" activeClassName="active">
              {contact[language]}
            </Link>
          </li>
          <li className="lang">
            <Language setIsAskingDefaultLang={setIsAskingDefaultLang} checkArchivePage={checkArchivePage()} />
          </li>
          {/* CV */}
          <ul className="navCV inSpMenu">
            <li>
              {isAuthenticated() &&
                <button
                  className={language === 'ja' ? "disactive" : "en disactive"}
                  onClick={() => { logout() }} >{logoutText[language]}</button>}
              {!isAuthenticated() &&
                <button
                  className={language === 'ja' ? "" : "en"}
                  onClick={() => { login() }} >{loginText[language]}</button>}
            </li>
            {!isAuthenticated() && <li className="signIn">
              <ul>
                {language === 'ja' ?
                  <li>初めての方は、<span onClick={() => { signup() }} ><span className="active">サインアップ</span></span></li>
                  :
                  <><li></li>
                    <li onClick={() => { signup() }} >
                      <span style={{ marginRight: '0.3rem' }}>or</span><span className="active">Sign up </span>
                    </li></>}
              </ul>
            </li>}
          </ul>
        </ul>

        {/* CV */}
        <ul className="navCV">
          {!isAuthenticated() && <li className="signIn">
            <ul>
              {language === 'ja' ?
                <><li>初めての方は、</li>
                  <li onClick={() => { signup() }} ><span className="active">サインアップ</span></li></>
                :
                <><li></li>
                  <li onClick={() => { signup() }} >
                    <span className="active">Sign up </span><span style={{ margin: '0 0.3rem' }}>or</span>
                  </li></>}
            </ul>
          </li>}
          <li>
            {isAuthenticated() && <button className="disactive" onClick={() => { logout() }} >{logoutText[language]}</button>}
            {!isAuthenticated() && <button onClick={() => { login() }} >{loginText[language]}</button>}
          </li>
        </ul>
      </nav>

      <nav className={"spMenu" + (checkArchivePage() ? ' archive' : '')}>
        {!checkArchivePage() && <h3>Menu</h3>}
        <button
          aria-label="Toggle menu"
          onClick={() => onSpMenuHandle()}
          className={getSpMenuClasses()}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </nav>
    </React.Fragment>
  )
}

export default Nav
