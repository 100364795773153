import React from "react"
import { Link, useIntl } from "gatsby-plugin-intl"
import "./css/footer.styl"
import Language from "./language"
import useNavTexts from "../hooks/use-nav-text"
import iconFacebook from "../images/svg/facebook.svg"
import iconContact from "../images/svg/contact.svg"
import logoJpAutoradiograph from "../images/svg/title-jp.svg"

// Auth0
import { login, logout, isAuthenticated, getProfile } from "../utils/auth"

const Footer = ({ location }) => {
  const {
    top,
    info,
    archive,
    history,
    shortmovie,
    print,
    patreon,
    facebook,
    loginText,
    logoutText,
  } = useNavTexts()[0]

  const intl = useIntl()
  const language = intl.locale

  return (
    <footer>
      {!(isAuthenticated() && location && location.pathname.indexOf("archive") >= 0) && <nav className="fNav">
        {/* !(isAuthenticated() && location && location.pathname.indexOf("archive") >= 0) && */}
        {/* Links */}
        <ul className={language === "en" ? "navLink" : "navLink ja"}>
          <li>
            <Link to="/" activeClassName="active">
              {top[language]}
            </Link>
          </li>
          <li>
            <Link
              to="/info"
              className={
                location && location.pathname.indexOf("info") >= 0
                  ? "active"
                  : ""
              }
            >
              {info[language]}
            </Link>
          </li>
          <li>
            <Link
              to="/archive"
              className={
                location && location.pathname.indexOf("archive") >= 0
                  ? "active"
                  : ""
              }
            >
              {archive[language]}
            </Link>
          </li>
          <li>
            <Link
              to="/history/press"
              className={
                location && location.pathname.indexOf("history") >= 0
                  ? "active"
                  : ""
              }
            >
              {history[language]}
            </Link>
          </li>
          <li>
            <Link to="/shortmovie" activeClassName="active">
              {shortmovie[language]}
            </Link>
          </li>
          <li>
            <Language />
          </li>
        </ul>

        {/* Icons */}
        <ul className="iconLink">
          <li>
            <a
              href={facebook[language]}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={iconFacebook} alt="Facebook" />
            </a>
          </li>
          <li>
            <Link to="/contact">
              <img src={iconContact} alt="Contact" />
            </Link>
          </li>
        </ul>

        {/* CV */}
        <ul className="navCV">
          {/* <li>
            <a
              href={patreon[language]}
              target="_blank"
              rel="noreferrer noopener"
            >
              Patreon
            </a>
          </li> */}
          <li>
            {isAuthenticated() && <button className={language === 'ja' ? "disactive" : "en disactive"} onClick={() => { logout() }} >{logoutText[language]}</button>}
            {!isAuthenticated() && <button className={language === 'ja' ? "" : "en"} onClick={() => { login() }} >{loginText[language]}</button>}
          </li>
        </ul>
      </nav>}
      <h2>
        <Link to="/">
          {language === "ja" ? (
            <img src={logoJpAutoradiograph} alt="放射線像" />
          ) : (
              "Autoradiograph"
            )}
        </Link>
      </h2>
    </footer>
  )
}

export default Footer
