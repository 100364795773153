import { useStaticQuery, graphql } from "gatsby"

const useInformationDateTitle = () => {
  const { allContentfulInformation } = useStaticQuery(
    graphql`
      query {
        allContentfulInformation(
          limit: 10
          sort: { order: DESC, fields: publishedDate }
        ) {
          nodes {
            title
            slug
            publishedDate(formatString: "YYYY/MM/DD")
            language
          }
        }
      }
    `
  )

  return allContentfulInformation.nodes
}

export default useInformationDateTitle
