import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import { isAuthenticated } from '../utils/auth';

const Language = ({ setIsAskingDefaultLang, checkArchivePage }) => {

  const handleChange = e => {
    if (e.target.value === 'defaultSelect') {
      setIsAskingDefaultLang(true)
      return
    }
    const lang = e.target.value
    changeLocale(lang)
  }

  const bullet = {
    marginRight: "6px",
  }

  return (
    <div>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <>
            <span style={bullet}>▾</span>
            <select className="language" onChange={handleChange} value={currentLocale}>
              <option key={currentLocale} value={currentLocale}>
                {currentLocale.toUpperCase()}
              </option>
              {languages
                .filter(lang => lang !== currentLocale)
                .map(lang => (
                  <option key={lang} value={lang}>
                    {lang.toUpperCase()}
                  </option>
                ))}
              {isAuthenticated() && !!checkArchivePage &&
                <option value="defaultSelect">{currentLocale === 'ja' ? '設定' : 'Setting'}</option>}
            </select>
          </>
        )}
      </IntlContextConsumer>
    </div>
  )
}

export default Language
