import React from "react"
import { Link, useIntl } from "gatsby-plugin-intl"
import _ from "lodash"
import useInformationDateTitle from "../hooks/use-information-date-title"
import "./css/info-top-comp.styl"

function Information() {
  const allInfoDateTitle = useInformationDateTitle()
  const intl = useIntl()
  const language = intl.locale
  const infoDateTitle = allInfoDateTitle.filter(
    data => data.language === language
  )
  const displayInfo = _.take(infoDateTitle, 2)

  return (
    <aside className="information">
      <ul>
        {displayInfo.map(info => (
          <React.Fragment key={info.slug}>
            <li className="field">{info.publishedDate}</li>
            <li className="content">
              <Link to={`/info/${info.slug}`}>{info.title}</Link>
            </li>
          </React.Fragment>
        ))}
      </ul>
    </aside>
  )
}

export default Information
