import React from "react"
import Img from "gatsby-image"
import useAllImgs from "../hooks/use-all-image"
import "./css/landscape.styl"

const Landscape = () => {
  const images = useAllImgs()
  const spLandscapeImg = images.find(
    img => img.childImageSharp.fluid.originalName === "landscape-sp@2x.jpg"
  )
  const pcLandscapeImg = images.find(
    img => img.childImageSharp.fluid.originalName === "wide-landscape@2x.jpg"
  )
  // const wideLandscapeImg = images.find(
  //   img => img.childImageSharp.fluid.originalName === "wide-landscape@2x.jpg"
  // )

  return (
    <section className="landscape">
      {/* <Img className="wide_pc" fluid={wideLandscapeImg.childImageSharp.fluid} /> */}
      <Img className="pc" fluid={pcLandscapeImg.childImageSharp.fluid} />
      <Img className="sp" fluid={spLandscapeImg.childImageSharp.fluid} />
    </section>
  )
}

export default Landscape
