import { useStaticQuery, graphql } from "gatsby"

const AllImgs = () => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "all" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 2600) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `) //(quality: 90)

  return allFile.nodes
}

export default AllImgs
