import { useStaticQuery, graphql } from "gatsby"

const NavTexts = () => {
  const { allNavJson } = useStaticQuery(
    graphql`
      query {
        allNavJson {
          nodes {
            title {
              en
              ja
            }
            top {
              en
              ja
            }
            info {
              en
              ja
            }
            archive {
              en
              ja
            }
            history {
              en
              ja
            }
            shortmovie {
              en
              ja
            }
            print {
              en
              ja
            }
            contact {
              en
              ja
            }
            patreon {
              en
              ja
            }
            facebook {
              en
              ja
            }
            loginText {
              en
              ja
            }
            logoutText {
              en
              ja
            }
          }
        }
      }
    `
  )

  return allNavJson.nodes
}

export default NavTexts
